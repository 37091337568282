<template>
  <div class="profile-page">
    <LoadingOverlay v-if="isLoading" />
    <CCard v-else-if="partnersProfilePages.length && !isGenerateNew">
      <CCardBody>
        <div class="d-flex justify-content-between">
          <h5 class="text-primary">Profile Page</h5>
          <div>
            <button
              v-if="false"
              name="btn-edit"
              class="btn text-primary"
              @click="startEditMode"
            >
              <span class="mr-1">Edit</span>
              <i class="fa-solid fa-pen-to-square"></i>
            </button>
            <button
              name="btn-regenerate"
              class="btn text-primary"
              @click="regenerateTemplate"
            >
              <span class="mr-1">Regenerate</span>
              <i class="fa-solid fa-arrows-rotate"></i>
            </button>
            <button
              name="btn-delete"
              class="btn text-primary"
              @click="openDeleteModal"
            >
              <span class="mr-1">Delete</span>
              <i class="fa-solid fa-trash"></i>
            </button>
          </div>
        </div>
        <div>
          <iframe
            id="iframe"
            ref="iframe"
            :srcdoc="htmlString"
            onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));'
            style="height: 200px; width: 100%; border: none; overflow: hidden"
          ></iframe>
        </div>
      </CCardBody>
    </CCard>
    <AITemplate
      :isEditModeInfo="isEditMode"
      :htmlFileString="htmlString"
      v-else
      @saveTemplate="createProfile"
    />
    <Modal
      v-if="deleteConfirmModal.isShowPopup"
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deleteModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import Vue from "vue";
import SLCProfile from "@/components/reusable/ProfilePage/SLCPage";
import DOHProfile from "@/components/reusable/ProfilePage/DOHPage";
import NHSProfile from "@/components/reusable/ProfilePage/NHSPage";
import Select from "@/components/reusable/Fields/Select.vue";
import AITemplate from "@/containers/ProfilePage/AITemplate";
import { BASE_URL } from "@/service_urls";

import Modal from "@/components/reusable/Modal";
import { CONSTANT } from "@/helpers/helper";
import { mapActions, mapGetters } from "vuex";
Vue.component("SLCProfile", SLCProfile);
Vue.component("DOHProfile", DOHProfile);
Vue.component("NHSProfile", NHSProfile);
export default {
  name: "AddProfilePage",
  components: {
    Select,
    AITemplate,
    Modal,
  },
  data() {
    return {
      partnerInfo: {},
      partners: CONSTANT.partners,
      isGenerateNew: false,
      isLoading: false,
      isEditMode: false,
      editInfo: null,
      htmlString: null,
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        data: null,
      },
    };
  },
  computed: {
    ...mapGetters(["partnersProfilePages", "getOrgIDFromAccessToken"]),
    options() {
      return {
        profileTemplate: this.partners
          .map((v) => ({
            code: v.partner_id,
            label: v.name,
            _rawData: v,
            component: v.component,
            thumbnail: v.thumbnail,
          }))
          .filter((v) => v.code === 1),
      };
    },
    partnerPage() {
      return this.partnersProfilePages?.length
        ? this.partnersProfilePages?.[0]
        : null;
    },
    partnerPageURL() {
      return this.partnersProfilePages?.length
        ? `${BASE_URL}${this.partnersProfilePages[0].link_to_doc?.replace(
            "/api/v1",
            ""
          )}`
        : false;
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "getPartnersPages",
      "createPartnerPage",
      "getHTML",
      "deleteOrganisationDocument",
    ]),
    createProfile(data) {
      const organisation_id = this.getOrgIDFromAccessToken;
      const payload = {
        organisation_id,
        file: data,
      };
      this.createPartnerPage(payload).then(async (res) => {
        if (this.isGenerateNew) {
          const organisation_document_id =
            this.partnerPage?.organisation_document_id;
        }
        this.initAction();
      });
    },
    openDeleteModal() {
      this.deleteConfirmModal.modalTitle = "NOTIFICATION";
      this.deleteConfirmModal.modalContent =
        "Are you sure you want to delete this Profile page?";
      this.deleteConfirmModal.isShowPopup = true;
    },
    deleteModalCallBack(action) {
      if (action === "Confirm") {
        const organisation_document_id =
          this.partnerPage?.organisation_document_id;
        this.deleteOldPartnerPage(
          organisation_document_id,
        );
      }
      this.deleteConfirmModal.isShowPopup = false;
      this.deleteConfirmModal.data = null;
    },
    deleteOldPartnerPage(organisation_document_id) {
      this.deleteOrganisationDocument(
        organisation_document_id,
      ).then((res) => {
        this.initAction();
      });
    },
    handleChangeSelect(name, value) {
      Vue.set(this.partnerInfo, name, value);
    },
    navToHome() {
      this.$router.push("/list-course");
    },
    initAction() {
      this.isLoading = true;
      this.isGenerateNew = false;
      this.getPartnersPages({
        all_rows: true,
        fetch_row_count: false,
        document_type_id: 62, // Profile page Document type id,
        organisation_id: this.getOrgIDFromAccessToken,
        order_by: "-organisation_document_id",
        deleted: false,
      }).then((res) => {
        this.isLoading = false;
        if (res) {
          this.htmlString = this.convertHTMLtoDoc(this.partnerPageURL);
        }
      });
    },
    startEditMode() {
      this.isEditMode = true;
      this.editInfo = {};
    },
    regenerateTemplate() {
      this.isGenerateNew = true;
    },
    convertHTMLtoDoc(url) {
      this.getHTML(url).then((result) => {
        this.htmlString = result?.data;
      });
    },
  },
  mounted() {
    this.initAction();
  },
};
</script>
<style lang="scss" scoped>
.profile-preview {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
</style>
