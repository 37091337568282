import axios from "axios";
import { BASE_URL } from "../service_urls";
const openai = {
  generatePartnerPage: (payload, organisation_id) =>
    axios.post(`${BASE_URL}/partners_page/?organisation_id=${organisation_id}`, payload),
  createPartnerPage:({organisation_id, payload}) => axios.post(`${BASE_URL}/partners_page/create?organisation_id=${organisation_id}`, payload),
  getPartnerPage:(query) => axios.get(`${BASE_URL}/partners_page/?${query}`),
  getHTML:(url) => axios.get(url),
  getGenerateResponse: (query) => axios.get(`${BASE_URL}/partners_page/partners_page?${query}`),
  getPartnerPageWithOrg: () => axios.get(`${BASE_URL}/partners_page/partners_profile_pages`),
  getUnsplashImages:(query) => axios.get(`https://api.unsplash.com/search/photos?query=${query}&client_id=${process.env.VUE_APP_UNSPLASH_ACCESS_KEY}`),
  stopPartnerPageGeneration:(partner_page_id) => axios.delete(`${BASE_URL}/partners_page/delete?partner_page_id=${partner_page_id}`),
};
export default openai;
